"use client";

import revalidatePath from "@/actions/revalidatePath";
// import { useRouter } from "next/navigation";

export default function Retry({ slug }: { slug: string }) {
	// const router = useRouter();

	function handleClick() {
		revalidatePath(slug, "page");
		// router.refresh();

		window.location.reload();
	}

	return (
		<div style={{ textAlign: "center", padding: "24px" }}>
			<h1 style={{ marginBottom: "12px" }}>
				Estamos cargando tu contenido, si actualizas tu página será más rápido
			</h1>
			<h2 style={{ marginBottom: "24px" }}>
				Puedes consultar nuestro menú superior para reanudar tu navegación
			</h2>
			<button
				style={{ border: "1px solid black", padding: "4px 8px" }}
				onClick={handleClick}
			>
				Recargar página
			</button>
		</div>
	);
}
